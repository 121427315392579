import { FunctionComponent, useState, useRef, useEffect } from 'react'

type Props = {
  mainText?: string
  subText?: string
}

const TofuLoaderV2: FunctionComponent<Props> = ({ mainText, subText }) => {
  const loadingTimer: any = useRef(null)
  const [loadingTimeout, setLoadingTimeout] = useState<Boolean>(false)

  useEffect(() => {
    loadingTimer.current = setTimeout(() => setLoadingTimeout(true), 12000)
    return () => {
      clearTimeout(loadingTimer.current)
    }
  }, [])

  if (loadingTimeout) {
    return (
      <div className="w-full bg-white flex flex-col justify-center items-center">
        <p>Something went wrong!</p>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <div
        className="flex flex-col justify-center items-center text-center w-full"
        style={{
          height: '600px',
        }}
      >
        <img
          style={{ width: '470px' }}
          src= {`${process.env.CDN_URL}/assets/icons/spot_counselling_loader.gif`}
          alt="Loading..."
        />
        {mainText && (
          <p className="text-primary md:text-2xl text-xl mb-2">{mainText}</p>
        )}
        {subText && (
          <p className="md:text-lg text-base text-lightgray-800">{subText}</p>
        )}
      </div>
    </div>
  )
}

export default TofuLoaderV2
