import React from "react";
import Head from "next/head";

type TitleProps = {
    selectedCountry: string;
  };
  
  const Title: React.FC<TitleProps> = ({ selectedCountry }) => (
    <Head>
      <title>
        Study in {selectedCountry} for Indian Student | Study Abroad{" "}
        {selectedCountry} | LeapScholar
      </title>
      <meta
        name="description"
        content={`Want to Study in ${selectedCountry} ? LeapScholar provides Personalized Guidance, IELTS Coaching, Financial Help, Visa Assurance. Get Counselling and Mentorship for ${selectedCountry} Today!`}
      />
      <meta
        property="og:image"
        content="https://leapassets.s3.ap-south-1.amazonaws.com/ielts-recording/1619511191304-logo@2x_(1)_(1).png"
      />
    </Head>
  );

export default Title