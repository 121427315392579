import api from "service/api"

export const getUserDataAndStatus = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=counselling/is_registered`,
    )
    if (res?.data?.success) {
      return res?.data?.data
    }
    throw new Error(res?.data?.message)
  } catch (err) {
    console.log(err || "Something went wrong. Please try after sometime")
  }
}
