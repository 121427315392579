import api from "service/api"

export const saveLandingPageForm = async (values: any) => {
  let res
  try {
    res = await api.post(`/api/scholarRoute?path=lead/country-form`, values)
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
  if (res?.data?.success) {
    return true
  } else {
    throw new Error(res?.data?.message)
  }
}

export const saveLandingPageFormV2 = async (values: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=lead/country-form/v2`,
      values,
    )
    if (res?.data?.success) {
      return true
    } else {
      throw new Error(res?.data?.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

export const saveLandingPageFormV3 = async (values: any) => {
  let res
  try {
    res = await api.post(`/api/scholarRoute?path=lead/country-form`, values, {
      headers: {
        "X-API-VERSION": "3",
      },
    })
  } catch (err) {
    throw new Error("Something went wrong. Please try after sometime")
  }
  if (res?.data?.success) {
    return true
  } else {
    throw new Error(res?.data?.message)
  }
}

export const probUserData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=counselling/calendar/show`,
    )
    if (res?.data?.success) {
      return res?.data?.data
    } else {
      throw new Error(res?.data?.message)
    }
  } catch (err) {
    console.log(err || "Something went wrong. Please try after sometime")
  }
}

export const getUserDataAndStatus = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=counselling/is_registered`,
    )
    if (res?.data?.success) {
      return res?.data?.data
    } else {
      throw new Error(res?.data?.message)
    }
  } catch (err) {
    console.log(err || "Something went wrong. Please try after sometime")
  }
}
